import {
  Button,
  Flex,
  Grid,
  Loader,
  Select,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconSearch } from "@tabler/icons";
import CoreModal from "components/core/CoreModal";
import CoreTable from "components/core/CoreTable";
import ExcelFileUpload from "components/core/ImageUpload/ExcelFileUpload";
import PageWrapper from "components/core/PageWrapper";
import EditParticipant from "components/forms/participants/EditParticipant";
import FileUploadContext from "context/FileUploadContext";
import { debounce } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useGetAllEventsContextMenu } from "services/enteties/events/useGetAllEventsContextMenu";
import {
  useDeleteParticipant,
  useGetParticipants,
  useUpdateIsPublicParticipant,
} from "services/enteties/participants";
import { useGetEmailSettings } from "services/enteties/participants/useGetEmailSettings";
import { useSendEmail } from "services/enteties/participants/useSendEmail";
import { useUpdateEmailSettings } from "services/enteties/participants/useUpdateEmailSettings";
import { useUploadParticipantsExcelFile } from "services/enteties/participants/useUploadParticipantsExcelFile";
import http from "services/http";
import { Event } from "types/event";
import { Participant } from "types/participant";

const Participants = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const handlePagination = (page: number) => setPage(page);
  const [deleteId, setDeleteId] = useState<any>("");
  const [selectedEvent, setSelectedEvent] = useState<string>("");
  const [eventId, setEventId] = useState<number>();
  const [editParticipant, setEditParticipant] = useState<Participant | null>(
    null
  );
  const [sendEmail, setSendEmail] = useState<any>("");
  const [isVisibleSendEmails, setIsVisibleSendEmails] =
    useState<boolean>(false);
  const [isVisibleEmailSettings, setIsVisibleEmailSettings] =
    useState<boolean>(false);
  const [tickets, setTickets] = useState(false);
  const { fileState, cleanupContextState, setFileState } =
    useContext(FileUploadContext);
  const { mutate: uploadFiles } = useUploadParticipantsExcelFile();
  const { data, isLoading, isFetching, refetch } = useGetParticipants(
    page,
    searchQuery,
    selectedEvent
  );
  const { data: emailSettingsData } = useGetEmailSettings();
  const { mutate: sendEmailMutate } = useSendEmail();
  const { mutate: emailSettingsMutate } = useUpdateEmailSettings();

  // Get events and map id and name
  const { data: eventsData, isLoading: isLoadingEvents } =
    useGetAllEventsContextMenu();
  const events = eventsData?.map((e: Event) => ({
    value: e.id,
    label: e.name,
  }));

  const handleEventChange = (value: string) => {
    setSelectedEvent(value);
    if (selectedEvent) refetch();
  };

  const { mutate: deleteParticipant } = useDeleteParticipant();

  const { mutate: updateParticipantIsPublic } = useUpdateIsPublicParticipant();

  const handleToggleHighlight = (id: number, isPublic: boolean) => {
    updateParticipantIsPublic(
      {
        id,
        is_public: Number(!isPublic),
      },
      {
        onSuccess: () =>
          showNotification({
            title: "Success",
            message: "Successfully updated",
          }),
        onError: (error: any) =>
          showNotification({
            title: "Error",
            message: error.response?.data?.message || "Something went wrong",
            color: "red",
          }),
      }
    );
  };

  const debouncedSearch = debounce((value: string) => {
    setSearchQuery(value);
  }, 500);

  const handleDownloadCsv = async () => {
    const response = await http(
      selectedEvent
        ? `/api/participants/export?filter[event_id]=${selectedEvent}`
        : "/api/participants/export"
    );
    const csvData = await response.data;
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "data.csv";
    downloadLink.click();
  };

  const handleSearchParticipants = () => (
    <Flex align="center" gap="xl">
      <Select
        placeholder="Select event"
        allowDeselect
        disabled={isLoadingEvents}
        icon={isLoadingEvents && <Loader size="sm" />}
        data={events ?? []}
        dropdownPosition="bottom"
        onChange={handleEventChange}
        searchable
      />
      <TextInput
        placeholder="Search"
        onChange={(event) => debouncedSearch(event.target.value)}
        icon={<IconSearch size={"0.8rem"} />}
      />
      <Button onClick={() => setIsVisibleEmailSettings(true)}>
        Email Settings
      </Button>
      <Button onClick={() => setIsVisibleSendEmails(true)}>Send Emails</Button>
      <Button onClick={() => setTickets(true)}>Import</Button>
      <Button onClick={handleDownloadCsv}>Download CSV</Button>
    </Flex>
  );

  const headerColumns = [
    { id: 1, title: "Id" },
    { id: 2, title: "Order id" },
    { id: 3, title: "Name" },
    { id: 4, title: "Badge name" },
    { id: 5, title: "Email" },
    { id: 6, title: "Company" },
    { id: 7, title: "Domain" },
    { id: 8, title: "Created" },
    { id: 9, title: "Public" },
    { id: 10, title: "Actions" },
  ];

  let rows = null;
  if (data?.data?.length) {
    rows = data?.data?.map((info: Participant, key: number) => (
      <tr key={`${info.id}-${key}`}>
        <td>{info.id}</td>
        <td>{info.order}</td>
        <td>{info.name}</td>
        <td>{info.badge_name}</td>
        <td>{info.email}</td>
        <td>{info.company_badge_name}</td>
        <td>{info.answers.my_domains}</td>
        <td>{new Date(info.created_at).toLocaleDateString()}</td>
        <td>
          <Switch
            checked={info.is_public}
            onChange={() => handleToggleHighlight(info.id, info.is_public)}
          />
        </td>
        <td>
          <Button
            onClick={() => {
              setEditParticipant(info);
            }}
            variant="subtle"
            size="xs"
          >
            Edit
          </Button>
          <Button
            onClick={() => setDeleteId(info.id)}
            variant="subtle"
            color="red"
            size="xs"
          >
            Delete
          </Button>
          <Button
            onClick={() => [setSendEmail(info.id)]}
            variant="subtle"
            color="green"
            size="xs"
          >
            Send Email
          </Button>
        </td>
      </tr>
    ));
  } else {
    rows = (
      <tr>
        <td
          colSpan={headerColumns.length}
          style={{
            textAlign: "center",
            padding: "20px 0px",
          }}
        >
          No results.
        </td>
      </tr>
    );
  }

  const {
    register: registerSendEmails,
    handleSubmit: handleSubmitSendEmails,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      from_order: 0,
      to_order: 0,
    },
  });

  const onSubmitSendEmails = (data: any) => {
    sendEmailMutate(
      { from: data.from_order, to: data.to_order, event_id: selectedEvent },
      {
        onSuccess: () => {
          setIsVisibleSendEmails(false);
          showNotification({
            title: "Success",
            message: "Successfully send",
          });
        },
        onError: (error: any) => {
          showNotification({
            title: "Error",
            message: error.response.data.message,
            color: "red",
          });
        },
      }
    );
  };

  const {
    control,
    register: registerEmailSettings,
    handleSubmit: handleSubmitEmailSettings,
    reset,
  } = useForm<any>({
    defaultValues: {
      categories: [],
    },
  });

  const onSubmitEmailSettings = (data: any) => {
    emailSettingsMutate(
      { categories: data.categories },
      {
        onSuccess: () => {
          setIsVisibleEmailSettings(false);
          showNotification({
            title: "Success",
            message: "Successfully send",
          });
        },
        onError: (error: any) => {
          showNotification({
            title: "Error",
            message: error.response.data.message,
            color: "red",
          });
        },
      }
    );
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "categories",
  });

  useEffect(() => {
    if (emailSettingsData) {
      reset({ categories: emailSettingsData });
    }
  }, [emailSettingsData, reset]);

  return (
    <PageWrapper title="Participants" action={handleSearchParticipants()}>
      <CoreModal
        opened={!!deleteId}
        onClose={() => setDeleteId("")}
        title={"Delete Speaker"}
      >
        <Text ta="center" fw={500} my={20}>
          Are you sure you want to delete this participant?
        </Text>
        <Flex justify="space-evenly">
          <Button onClick={() => setDeleteId("")}>Cancel</Button>
          <Button
            onClick={() => {
              deleteParticipant(deleteId);
              setDeleteId("");
            }}
          >
            Confirm
          </Button>
        </Flex>
      </CoreModal>
      <CoreModal
        opened={tickets}
        onClose={() => {
          setTickets(false);
          setFileState([]);
        }}
        title={"Tickets import"}
      >
        <ExcelFileUpload
          placeholderText="Click to select"
          maxFiles={1}
          setEventId={setEventId}
        />
        <Flex justify="space-evenly">
          <Button
            onClick={() => {
              setTickets(false);
              setFileState([]);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              uploadFiles(
                { id: "tickets", eventId: eventId, files: fileState },
                {
                  onSuccess: () => {
                    showNotification({
                      title: "Success",
                      message: "Successfully updated",
                    });
                    cleanupContextState();
                  },
                  onError: (error: any) => {
                    showNotification({
                      title: "Error",
                      message: error.response.data.message,
                    });
                  },
                }
              );

              setTickets(false);
            }}
          >
            Confirm
          </Button>
        </Flex>
      </CoreModal>
      <CoreModal
        title="Edit participant"
        onClose={() => setEditParticipant(null)}
        opened={!!editParticipant}
      >
        <EditParticipant participant={editParticipant} />
      </CoreModal>
      <CoreModal
        title="Send Email"
        onClose={() => setSendEmail(null)}
        opened={!!sendEmail}
      >
        <Text ta="center" fw={500} my={20}>
          Are you sure you want to send an email?
        </Text>
        <Flex justify="space-evenly">
          <Button onClick={() => setSendEmail(null)}>Cancel</Button>
          <Button
            onClick={() => {
              sendEmailMutate(
                { id: sendEmail },
                {
                  onSuccess: () => {
                    setIsVisibleSendEmails(false);
                    showNotification({
                      title: "Success",
                      message: "Successfully send",
                    });
                  },
                }
              );
              setSendEmail(null);
            }}
          >
            Confirm
          </Button>
        </Flex>
      </CoreModal>
      <CoreModal
        title="Send Emails"
        onClose={() => setIsVisibleSendEmails(false)}
        opened={!!isVisibleSendEmails}
      >
        <form onSubmit={handleSubmitSendEmails(onSubmitSendEmails)}>
          <Grid mb={20}>
            <Grid.Col md={12} lg={3}>
              <TextInput
                withAsterisk
                {...registerSendEmails("from_order")}
                placeholder="From"
                label="From"
                error={errors.from_order?.message as string}
              />
            </Grid.Col>
            <Grid.Col md={12} lg={3}>
              <TextInput
                withAsterisk
                {...registerSendEmails("to_order")}
                placeholder="To"
                label="To"
                error={errors.to_order?.message as string}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="space-evenly">
            <Button onClick={() => setIsVisibleSendEmails(false)}>
              Cancel
            </Button>
            <Button type="submit">Confirm</Button>
          </Flex>
        </form>
      </CoreModal>
      <CoreModal
        title="Email Settings"
        onClose={() => setIsVisibleEmailSettings(false)}
        opened={!!isVisibleEmailSettings}
      >
        <form onSubmit={handleSubmitEmailSettings(onSubmitEmailSettings)}>
          {fields.map((field, index) => {
            return (
              <Grid key={field.id} mb={20}>
                <Flex align="flex-end">
                  <Grid.Col md={12} lg={3}>
                    <TextInput
                      withAsterisk
                      {...registerEmailSettings(
                        `categories.${index}.from` as const,
                        {
                          required: "This field is required",
                        }
                      )}
                      placeholder="From"
                      label="From"
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={3}>
                    <TextInput
                      withAsterisk
                      {...registerEmailSettings(
                        `categories.${index}.to` as const,
                        {
                          required: "This field is required",
                        }
                      )}
                      placeholder="To"
                      label="To"
                    />
                  </Grid.Col>
                  <Grid.Col md={12} lg={3}>
                    <Button onClick={() => remove(index)}>Remove</Button>
                  </Grid.Col>
                </Flex>
              </Grid>
            );
          })}
          <Flex justify="space-between">
            <Button type="button" onClick={() => append({ from: "", to: "" })}>
              Add Category
            </Button>
            <Button type="submit">Save</Button>
          </Flex>
        </form>
      </CoreModal>
      <CoreTable
        rows={rows}
        page={page}
        headerColumns={headerColumns}
        perPage={data?.meta?.per_page}
        totalResults={data?.meta?.total}
        from={data?.meta?.from}
        to={data?.meta?.to}
        lastPage={data?.meta?.last_page}
        loading={isLoading}
        fetching={isFetching}
        handlePagination={data?.data?.length > 0 ? handlePagination : undefined}
      />
    </PageWrapper>
  );
};

export default Participants;
