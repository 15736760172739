import http from "services/http";
import { useMutation } from "@tanstack/react-query";

export const useUpdateEmailSettings = () => {
  return useMutation({
    mutationFn: (reqData: { categories: { from: string; to: string }[] }) => {
      const { categories } = reqData;
      return http.post(`/api/categories/store`, {
        categories: categories,
      });
    },
  });
};
