import { useQuery } from "@tanstack/react-query";
import http from "services/http";

export const useGetEmailSettings = () => {
  return useQuery(["email-categories"], () => http.get("/api/categories"), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    select(data) {
      return data.data;
    },
  });
};
