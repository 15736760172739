import { useContext } from "react";
import { Grid, Text, Image, Card, createStyles } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useDeleteImage } from "services/enteties/utilities";
import ImageUploadContext from "context/FileUploadContext";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
const useStyles = createStyles((theme) => ({
  link: {
    marginTop: 10,
    textDecoration: "underline",
    color: theme.colors.blue,
    cursor: "pointer",
    textAlign: "center",
  },
}));

const MultiplePhotosUpload = ({
  placeholderText,
  currentPhotos,
  queryKey,
}: any) => {
  const { imagesState, setImagesState, deleteImageInImages } =
    useContext(ImageUploadContext);
  const { mutate: deleteImage } = useDeleteImage(queryKey);
  const { classes } = useStyles();

  const uploadedPhotosPreview = imagesState?.map((image: any, key: number) => {
    const imageUrl = URL.createObjectURL(image);
    return (
      <Grid.Col span={4} key={key}>
        <Image
          width={200}
          height={200}
          mx="auto"
          src={imageUrl}
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />

        <Text className={classes.link} onClick={() => deleteImageInImages(key)}>
          Remove photo
        </Text>
      </Grid.Col>
    );
  });

  const currentPhotosPreview = currentPhotos?.map((image: any, key: number) => {
    return (
      <Grid.Col md={6} lg={4} key={key}>
        <Image width={200} height={200} mx="auto" src={image.path} />
        <Text
          className={classes.link}
          onClick={() =>
            deleteImage(image.id, {
              onSuccess: () =>
                showNotification({
                  title: "Success",
                  message: "Successfully deleted",
                }),
              onError: (error: any) =>
                showNotification({
                  title: "Error",
                  icon: <IconX />,
                  message: error.response.data.message,
                }),
            })
          }
        >
          Remove photo
        </Text>
      </Grid.Col>
    );
  });

  return (
    <Card>
      <Dropzone
        disabled={currentPhotos?.length + imagesState.length >= 5}
        maxFiles={3 - currentPhotos?.length}
        accept={IMAGE_MIME_TYPE}
        onDrop={setImagesState}
      >
        <Text ta="center">{placeholderText}</Text>
        <Text ta="center" fz="sm" color="dimmed">
          or drag over
        </Text>
      </Dropzone>
      <Grid grow mt={20}>
        {currentPhotosPreview}
        {uploadedPhotosPreview}
      </Grid>
    </Card>
  );
};

export default MultiplePhotosUpload;
