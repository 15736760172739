import http from "services/http";
import { useMutation } from "@tanstack/react-query";

export const useSendEmail = () => {
  return useMutation({
    mutationFn: (reqData: {
      id?: any;
      from?: number;
      to?: number;
      event_id?: string;
    }) => {
      const { id, from, to, event_id } = reqData;
      return http.post(`/api/participants/send-email`, {
        method: "post",
        participant_id: id,
        from_order: from,
        to_order: to,
        event_id: event_id,
      });
    },
  });
};
